import React from 'react';

function Title() {

  const text = ` /$$$$$$$                                                                    /$$$$$$           /$$                           /$$          
| $$__  $$                                                                  |_  $$_/          | $$                          | $$          
| $$  \\ $$ /$$   /$$ /$$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$         | $$    /$$$$$$$| $$  /$$$$$$  /$$$$$$$   /$$$$$$$  /$$$$$$$
| $$  | $$| $$  | $$| $$__  $$ /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$        | $$   /$$_____/| $$ |____  $$| $$__  $$ /$$__  $$ /$$_____/
| $$  | $$| $$  | $$| $$  \\ $$| $$  \\ $$| $$$$$$$$| $$  \\ $$| $$  \\ $$        | $$  |  $$$$$$ | $$  /$$$$$$$| $$  \\ $$| $$  | $$|  $$$$$$ 
| $$  | $$| $$  | $$| $$  | $$| $$  | $$| $$_____/| $$  | $$| $$  | $$        | $$   \\____  $$| $$ /$$__  $$| $$  | $$| $$  | $$ \\____  $$
| $$$$$$$/|  $$$$$$/| $$  | $$|  $$$$$$$|  $$$$$$$|  $$$$$$/| $$  | $$       /$$$$$$ /$$$$$$$/| $$|  $$$$$$$| $$  | $$|  $$$$$$$ /$$$$$$$/
|_______/  \\______/ |__/  |__/ \\____  $$ \\_______/ \\______/ |__/  |__/      |______/|_______/ |__/ \\_______/|__/  |__/ \\_______/|_______/ 
                               /$$  \\ $$                                                                                                  
                              |  $$$$$$/                                                                                                  
                               \\______/                                                                                                   `;
  const subText = ` _____  _   _   ___  ______ _____ ___________         __  
/  __ \\| | | | / _ \\ | ___ \\_   _|  ___| ___ \\       /  | 
| /  \\/| |_| |/ /_\\ \\| |_/ / | | | |__ | |_/ /       \`| | 
| |    |  _  ||  _  ||  __/  | | |  __||    /         | | 
| \\__/\\| | | || | | || |     | | | |___| |\\ \\        _| |_
 \\____/\\_| |_/\\_| |_/\\_|     \\_/ \\____/\\_| \\_|       \\___/`;
 

  return (
    <div style={{ color: '#ffd480', fontFamily: 'monospace' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <pre style={{ fontSize: 'small', marginLeft: 'auto', marginRight: 'auto', marginTop: 0, paddingTop: '24px', marginBottom: '12px', backgroundColor: 'black' }}>
          {text}
        </pre>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <pre style={{ fontSize: 'small', marginLeft: 'auto', marginRight: 'auto', marginTop: 0, paddingTop: 0, marginBottom: '24px', backgroundColor: 'black' }}>
          {subText}
        </pre>
      </div> 
    </div>

  );
}

export default Title;
